import whitelist_wallets from '../wallets/Whitelists.json';
import whitelist_test_wallets from '../wallets/test-whitelist.json';

import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

import { Buffer } from 'buffer';
import {getProvider} from "./web3";

// @ts-ignore
window.Buffer = Buffer;

const getWhitelistProof = async (address) => {
    const whitelist = process.env.REACT_APP_IS_TESTING === "true" ? whitelist_test_wallets : whitelist_wallets;
    if (whitelist.length < 1) {
        return [];
    }
    let proof = await lookupProof(whitelist, address);
    if(proof === 0) return [];
    return proof;
}

const lookupProof = async (list, address) => {
    const regex = new RegExp(list.join("|"), "i");
    const provider = getProvider();
    let ens = null;
    try {
        ens = await provider.lookupAddress(address);
    } catch {}
    let foundByEns = 0;
    if (ens != null) {
        if (regex.test(ens)) {
            foundByEns = 1;
        } else if (regex.test(address)) {
            foundByEns = 0;
        } else {
            return 0;
        }
    } else {
        if (!regex.test(address)) return 0;
    }

    // Build the Merkle Tree
    const leafNodes = list.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    return foundByEns === 1 ? merkleTree.getHexProof(keccak256(ens)) : merkleTree.getHexProof(keccak256(address));
}

export default getWhitelistProof;