import React, { useState, useEffect } from 'react';
import "../../assets/custom-toastify.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import screenBg from '../../assets/img/mint-bg-pat.webp';
import topLeft from '../../assets/img/mint-top-left.svg';
import topRight from '../../assets/img/mint-top-right.svg';
import mintCounter from '../../assets/img/mint-counter.webp';

import mintUALogo from '../../assets/img/mint-ua-logo.webp';
import mintFlow from '../../assets/img/mint-flow.webp';
import soldOut from '../../assets/img/sold-out.webp';

import mintVecMain from '../../assets/img/mint-vec-main.svg';
import mintVecMainMobile from '../../assets/img/mint-vec-main-mobile.svg';

import mintPlus from '../../assets/img/mint-plus.svg';
import mintMinus from '../../assets/img/mint-minus.svg';
import mintCountBg from '../../assets/img/mint-count-bg.svg';

import { ReactComponent as TabBg } from '../../assets/img/tabs-btn-bg.svg';

import mintLogo from '../../assets/img/mint-logo.webp';

import mintBottomLeft from '../../assets/img/mint-bottom-left.webp';
import mintBottomRight from '../../assets/img/mint-bottom-right.webp';
import mintBottomMobile from '../../assets/img/mint-bottom-mobile.webp';

import mintWalletSpot from '../../assets/img/mint-wallet-spot.svg';
import mintWalletSpotDark from '../../assets/img/mint-wallet-spot-dark.svg';

import mintMax from '../../assets/img/mint-max.svg';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../component/ScrollToTop';


import {connectWallet, getContract, getReadOnlyContract, getProvider} from "../../utils/web3";
import longAddressHandler from "../../utils/longAddressHandler";
import {ethers} from "ethers";
import getWhitelistProof from "../../utils/getWhitelistProof";
import getElderlistProof from "../../utils/getElderlistProof";

const renderer = ({ days, hours, minutes, seconds }) => {
    return <div className="flex items-center gap-2">
        <span>{days < 10 ? '0' + days : days}</span>
        <span>:</span>
        <span>{hours < 10 ? '0' + hours : hours}</span>
        <span>:</span>
        <span>{minutes < 10 ? '0' + minutes : minutes}</span>
        <span>:</span>
        <span>{seconds < 10 ? '0' + seconds : seconds}</span>
    </div>
}

const MintPage = () => {
    const [state_count, setCount] = useState(1);
    const [address, setAddress] = useState("");
    const [isConnected, setIsConnected] = useState(false);
    const [supply, setSupply] = useState(0);
    const [max_pub_mints, setPublicMaxMint] = useState(1);
    const [max_wl_mints, setWlMaxMint] = useState(1);
    const [max_eld_mints, setEldMaxMint] = useState(1);

    const [publicMint, isPublicActive] = useState(false);
    const [whitelistMint, isWlActive] = useState(false);
    const [elderMint, isElderActive] = useState(false);

    const [total_price, setTotalPrice] = useState(0);

    const [wl_proof, setWlProof] = useState("");
    const [el_proof, setElProof] = useState("");

    //Get user contract reads
    const [balance, setUserBalance] = useState("0");

    const max_amount = elderMint ? max_eld_mints : whitelistMint ? max_wl_mints : max_pub_mints;


    // async function isSoldOut(){
    //     return await (await getContract()).totalSupply();
    // } To change with useEffect so it only fetches the value once



    //I need wallet balance, mint price, mints allowed,
    //cost, saleDiscount, maxSupply,

    //gets public max mints
    useEffect(() => {
        const fn = async () => {
            const ReadOnlyContract = await getReadOnlyContract();

            //Currently active sale
            const pba = await ReadOnlyContract.publicMintEnabled();
            isPublicActive(pba);
            const wla = await ReadOnlyContract.whitelistMintEnabled();
            isWlActive(wla);
            const eda = await ReadOnlyContract.elderListMintEnabled();
            isElderActive(eda);

            //Mints allowed depending on your role
            const pma = await ReadOnlyContract.maxMintAmountPublic();
            setPublicMaxMint(Number(pma));
            const wma = await ReadOnlyContract.maxMintAmountWhitelist();
            setWlMaxMint(Number(wma));
            const ema = await ReadOnlyContract.maxMintAmountElderList();
            setEldMaxMint(Number(ema));



            //Gets currently minted supply
            const s = await ReadOnlyContract.totalSupply();
            setSupply(Number(s));
        }
        fn();
    }, [])

    function isASaleActive(){
        return (publicMint || whitelistMint || elderMint);
    }


    const connectClick = async () => {
        try {
            let address = (await connectWallet()).address;
            setAddress(address)
            if(address.length > 0) {
                setIsConnected(true);
                const provider = await getProvider();
                const balance = await provider.getBalance(address);
                setUserBalance(parseFloat(ethers.formatEther(balance)).toFixed(3));

                let wl_proof = await getWhitelistProof(address);
                setWlProof(wl_proof);

                let el_proof = await getElderlistProof(address);
                setElProof(el_proof);

                console.log(wl_proof, el_proof)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const isSoldOut = true;

    const mintPrice = () =>{
        if(elderMint) return 0.03;
        else if (whitelistMint) return 0.035;

        return 0.035;
    }

    function setMintCount(op) {
        let count = state_count;
        if (op > 0) count = state_count === max_amount? count : count + 1;
        else count = state_count === 1 ? count : count - 1;
        setCount(count);
        setTotalPrice(count*mintPrice())
    }



    const mint = async () => {
        if(!isASaleActive()) {
            toast("No sale active");
            return;
        }
        if (address.length === 0) {
            toast("Please connect your wallet first");
            return;
        }
        if (whitelistMint && wl_proof.length === 0) {
            toast("You are not whitelisted");
            return;
        }
        if (elderMint && el_proof.length === 0) {
            toast("You are not elderlisted");
            return;
        }
        if (state_count === 0) {
            toast("Please select the number of NFTs to mint");
            return;
        }
        try {
            const contract = await getContract();
            let tx;
            if(elderMint) {
                tx = await contract.elderListMint(state_count, el_proof, {value: ethers.parseEther(mintPrice().toString()) * BigInt(state_count.toString())});
            } else if (whitelistMint) {
                tx = await contract.whitelistMint(state_count, wl_proof, {value: ethers.parseEther(mintPrice().toString()) * BigInt(state_count.toString())});
            } else {
                tx = await contract.mint(state_count, {value: ethers.parseEther(mintPrice().toString()) * BigInt(state_count.toString())});
            }
            await tx.wait();
            toast("NFT minted successfully");
        } catch (e) {
            console.log(e, e.stack)
            toast(e.reason ?? "An error occurred");
        }
    }


    return (
        <>
            <ToastContainer
                theme="dark"/>
            <div className="relative z-10 w-full min-h-screen bg-center bg-cover lg:text-white lg:bg-[#222] child:z-10" style={{ backgroundImage: 'url(' + screenBg + ')' }}>

                {/* LARGE */}

                <div className="absolute top-0 left-0 hidden -z-10 lg:block">
                    <img src={topLeft} alt="" />
                </div>
                <div className="absolute top-0 left-0 z-10 hidden lg:block">
                    <img src={mintCounter} alt="" />
                    {/*<div className="absolute right-28 xl:right-20 bottom-24 xl:bottom-20 rotate-[27deg] text-3xl xl:text-4xl font-ds text-[#F42272]">*/}
                    {/*    <Countdown date={Date.now() + 683140000} renderer={renderer} />*/}
                    {/*</div>*/}
                </div>
                <div className="absolute top-0 right-0 z-30 hidden cursor-pointer lg:block" onClick={connectClick}>
                    <img src={topRight} alt="" />
                    <div className="absolute flex flex-col items-center pb-6 pl-6 text-center -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                        <img src={mintWalletSpot} alt="" />
                        <div className="mt-4 font-bold">{isConnected
                            ? `Connected: ${longAddressHandler(address)}`
                            : `CONNECT YOUR WALLET`}</div>
                    </div>
                </div>
                <div className="absolute bottom-0 left-0 hidden w-1/4 -z-10 lg:block">
                    <img src={mintBottomLeft} alt="" />
                </div>
                <div className="absolute bottom-0 right-0 hidden w-1/4 -z-10 lg:block">
                    <img src={mintBottomRight} alt="" />
                </div>
                <div className="hidden w-11/12 mx-auto xl:w-9/12 lg:block">
                    <div className="flex flex-col min-h-screen justify-between">
                        <div className="flex justify-center text-center font-sp text-outline-3 text-[78px] text-[#F8D53B] pt-5">
                            <div className="relative z-10 inline-block">
                                <div className="absolute -top-5 translate-x-1/4 right-full">
                                    <img src={mintUALogo} alt="" className="max-w-max" />
                                </div>
                                <div className="absolute top-0 -translate-x-1/3 left-full -z-10">
                                    <img src={mintFlow} alt="" className="max-w-max" />
                                </div>
                                <div>
                                    HOUSEHAEDS AVAILABLE NOW!
                                </div>
                            </div>
                        </div>
                        {/* TABS */}
                        <div className="flex justify-center mt-6 mb-12">
                            { isASaleActive() || isSoldOut ?
                            <div className="inline-flex mr-10">
                                <div className="relative z-10 -mx-10">
                                    <div className= "h-[42px] min-w-[260px] -z-20" style={{color: (whitelistMint || publicMint || isSoldOut) ? "#888" : "#fff"}}>
                                        <TabBg  width="100%" height="42" />
                                    </div>
                                    <div style={{ color:(whitelistMint||publicMint) ? "#fff" : "#F42272"}} className={"absolute mr-5 font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"}>ELDERS</div>
                                    {(whitelistMint || publicMint || isSoldOut) ? <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        <img src={soldOut} alt="" />
                                    </div>: ''}
                                </div>
                                <div className="relative z-10 -mx-10">
                                    <div className="h-[42px] min-w-[260px] -z-10" style={{color: (publicMint || elderMint || isSoldOut) ? "#888" :"#fff"}}>
                                        <TabBg width="100%" height="42" />
                                    </div>
                                    <div style={{ color:(publicMint || elderMint) ? "#fff" : "#F42272"}} className={"absolute mr-5 font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"}>WHITE LIST</div>
                                    {(publicMint || isSoldOut) ? <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        <img src={soldOut} alt="" />
                                    </div>: ''}
                                </div>
                                <div className="relative z-10 -mx-10 cursor-pointer">
                                    <div className="h-[42px] min-w-[260px]" style={{color: (whitelistMint || elderMint || isSoldOut) ? "#888" : "#fff"}}>
                                        <TabBg width="100%" height="42" />
                                    </div>
                                    <div style={{ color:(whitelistMint || elderMint) ? "#fff" : "#F42272"}} className={"absolute mr-5 font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"}>PUBLIC</div>
                                    {(isSoldOut) ? <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        <img src={soldOut} alt="" />
                                    </div>: ''}
                                </div>
                            </div> :
                            <p><em>*Sale coming soon...*</em></p>}
                        </div>

                        <div className="relative flex justify-center pt-10 mt-10">
                            <img src={mintVecMain} alt="" />
                            <div className="absolute z-10 -translate-x-1/2 top-40 left-1/2">
                                <div className="text-5xl text-center xl:text-[62px] font-sp uppercase mb-10">Mint your HouseHaeds here!</div>

                                <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                    <div className="max-w-[75%] shrink-0">WALLET BALANCE</div>
                                    <div className="w-full">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="shrink-0">{(address) ? balance+" ETH": ""}</div>
                                </div>
                                <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                    <div className="max-w-[75%] shrink-0">MINT PRICE</div>
                                    <div className="w-full">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="shrink-0">{mintPrice()} ETH</div>
                                </div>
                                <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                    <div className="max-w-[75%] shrink-0">AMOUNT</div>
                                    <div className="w-1/3">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="inline-flex items-center w-full">
                                        <div className="flex items-center justify-center cursor-pointer h-14 w-14" onClick={() => setMintCount(-1)}><img src={mintMinus} alt="" /></div>
                                        <div className="relative cursor-pointer">
                                            <img src={mintCountBg} alt="" />
                                            <div className="absolute text-black -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                {state_count}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center cursor-pointer h-14 w-14" onClick={() => setMintCount(1)}><img src={mintPlus} alt="" /></div>
                                        <div className="relative p-4 cursor-pointer" onClick={() => {setCount(max_amount); setTotalPrice(max_amount*mintPrice())}}>
                                            <img src={mintMax} alt="" className="max-w-max" />
                                            <div className="absolute text-black -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                                Max
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4 font-bold text-[22px]">
                                    <div className="max-w-[75%] shrink-0">TOTAL</div>
                                    <div className="w-full">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="shrink-0">{(state_count ===1)? mintPrice() : total_price.toFixed(3)} ETH</div>
                                </div>

                                <div onClick={mint} className="flex justify-center mt-10">
                                    <img className="md:w-2/5 cursor-pointer" src={mintLogo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SMALL */}
                <div className="lg:hidden">
                    <div className="flex justify-center pt-7" onClick={connectClick}>
                        <img src={mintWalletSpotDark} alt="" />
                    </div>
                    <span className="ml-2 text-white justify-center text-[#222222] flex pb-5 ">Connect Wallet</span>

                    {/*TABS (small)*/}
                    <div className="flex justify-center mt-6 mb-12 text-white">
                        { isASaleActive()  || isSoldOut ?
                        <div className="inline-flex -mx-3">

                            <div className="relative z-10 -mx-6">
                                <div className="h-8 min-w-[160px]" style={{color: (whitelistMint || publicMint || isSoldOut) ? "#222" : "#fff"}}>
                                    <TabBg width="100%" height="32" />
                                </div>
                                <div style={{ color:(whitelistMint||publicMint || isSoldOut) ? "#fff" : "#F42272"}} className="absolute mr-5 text-xs font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">ELDERS</div>
                                {(whitelistMint || publicMint || isSoldOut) ? <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                    <img src={soldOut} alt="" />
                                </div>: ''}
                            </div>


                            <div className="relative z-10 -mx-6">
                                <div className="h-8 min-w-[160px] -z-10"  style={{color: (publicMint || elderMint || isSoldOut) ? "#222" :"#fff"}}>
                                    <TabBg width="100%" height="32" />
                                </div>
                                <div style={{ color:(publicMint || elderMint || isSoldOut) ? "#fff" : "#F42272"}} className="absolute mr-5 text-xs font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">W.LIST</div>
                                {(publicMint || isSoldOut) ? <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                    <img src={soldOut} alt="" />
                                </div>: ''}
                            </div>


                            <div className="relative -mx-6 cursor-pointer -z-20" >
                                <div className="h-8 min-w-[160px]"  style={{color: (whitelistMint || elderMint || isSoldOut) ? "#222" : "#fff"}}>
                                    <TabBg width="100%" height="32" />
                                </div>
                                <div style={{ color:(whitelistMint || elderMint || isSoldOut) ? "#fff" : "#F42272"}} className="absolute mr-5 text-xs font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">PUBLIC</div>
                                {(isSoldOut) ? <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                    <img src={soldOut} alt="" />
                                </div>: ''}
                            </div>
                        </div>: <p className="text-black"><em>*Sale coming soon...*</em></p>}
                    </div>

                    <div className="flex justify-center text-center font-sp text-outline-3 leading-none text-5xl sm:text-[48px] text-[#F8D53B] pt-5">
                        <div className="relative z-10 inline-block">
                            <div className="absolute translate-x-[40%] -top-5 right-full">
                                <img src={mintUALogo} alt="" className="scale-75 max-w-max" />
                            </div>
                            <div className="absolute top-0 -translate-x-1/2 left-full -z-10">
                                <img src={mintFlow} alt="" className="scale-75 max-w-max" />
                            </div>
                            <div>
                                HOUSEHAEDS <br /> AVAILABLE NOW!
                            </div>
                        </div>
                    </div>

                    {/*<div className="flex justify-center py-10 text-5xl font-ds">*/}
                    {/*    <Countdown date={Date.now() + 683140000} renderer={renderer} />*/}
                    {/*</div>*/}

                    <div className="relative flex justify-center pt-10 mt-10 text-white">
                        <img src={mintVecMainMobile} alt="" />
                        <div className="absolute top-28 left-0 h-[calc(100%-224px)] w-full bg-[#F42272]"></div>
                        <div className="absolute z-10 w-full px-5 -translate-x-1/2 top-40 left-1/2">
                            <div className="text-center text-[48px] font-sp uppercase mb-5 leading-none mb-10">Mint your HouseHaeds here!</div>

                            <div className="flex items-center gap-4 mb-4 font-bold">
                                <div className="max-w-[75%] shrink-0">BALANCE</div>
                                <div className="w-full">
                                    <div className="w-full h-0.5 bg-white"></div>
                                </div>
                                <div className="shrink-0">{(address) ? balance+" ETH": ""}</div>
                            </div>
                            <div className="flex items-center gap-3 mb-4 font-bold">
                                <div className="max-w-[75%] shrink-0">AMOUNT</div>
                                <div className="w-1/4 sm:w-full">
                                    <div className="w-full h-0.5 bg-white"></div>
                                </div>
                                <divs className="inline-flex items-center">
                                    <div className="flex items-center justify-center cursor-pointer h-14 w-14"  onClick={() => setMintCount(-1)}><img src={mintMinus} alt="" /></div>
                                    <div className="relative cursor-pointer">
                                        <img src={mintCountBg} alt="" />
                                        <div className="absolute text-black -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                            {state_count}
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center cursor-pointer h-14 w-14"  onClick={() => setMintCount(1)}><img src={mintPlus} alt="" /></div>
                                    <div className="relative cursor-pointer" onClick={() => {setCount(max_amount); setTotalPrice(max_amount*mintPrice())}}>
                                        <img src={mintMax} alt="" />
                                        <div className="absolute text-xs text-black -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                            Max
                                        </div>
                                    </div>
                                </divs>
                            </div>
                            <div className="flex items-center gap-4 font-bold">
                                <div className="max-w-[75%] shrink-0">TOTAL</div>
                                <div className="w-full">x
                                    <div className="w-full h-0.5 bg-white"></div>
                                </div>
                                <div className="shrink-0">{(state_count ===1)? mintPrice() : total_price.toFixed(3)} ETH</div>
                            </div>

                            <div onClick={mint} className="flex justify-center">
                                <img src={mintLogo} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center pt-12 -mt-40 bg-black">
                        <img src={mintBottomMobile} alt="" className="z-20" />
                    </div>
                </div>

                <Link to="/" className="fixed z-50 px-4 py-2 font-bold text-black bg-white rounded cursor-pointer hover:text-primary bottom-4 right-4">Back to Home</Link>
            </div>
            <ScrollToTop />
        </>
    )
}

export default MintPage