import {ethers} from "ethers";
import abi from "./abi.json"; //This is a test ABI from Teenage Innovators

export async function getContract () {
    const contractAddress = process.env.REACT_APP_IS_TESTING === "true" ? process.env.REACT_APP_TESTING_CONTRACT_ADDRESS : process.env.REACT_APP_CONTRACT_ADDRESS;
    const provider = getProvider();
    const signer = await provider.getSigner();
    return new ethers.Contract(contractAddress, abi, signer);
}

export function getReadOnlyContract () {
    const contractAddress = process.env.REACT_APP_IS_TESTING === "true" ? process.env.REACT_APP_TESTING_CONTRACT_ADDRESS : process.env.REACT_APP_CONTRACT_ADDRESS;
    const url = process.env.REACT_APP_IS_TESTING === "true" ? process.env.REACT_APP_TESTING_MUMBAI_PROVIDER: process.env.REACT_APP_POLY_PROVIDER;
    const provider = new ethers.JsonRpcProvider(url);
    return new ethers.Contract(contractAddress, abi, provider);
}

export function getProvider () {
    return new ethers.BrowserProvider(window.ethereum)
}

export async function connectWallet () {
    const provider = getProvider();
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    return {address: signerAddress};
}

export async function changeNetwork () {
    console.log("Changing network");
    const chainId = process.env.REACT_APP_IS_TESTING === "true" ? "5" : "1";
    if (window.ethereum && window.ethereum.networkVersion !== chainId) {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: process.env.REACT_APP_IS_TESTING === "true" ? '0x5' : '0x1' }], // chainId must be in hexadecimal numbers
            });
        } catch (error) {
            console.error(error);
        }
    }
}