import { Route, Routes } from 'react-router-dom';

import MintPage from './pages/Mint';
import WalletChecker from "./pages/Checker";

import 'aos/dist/aos.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  return (
    <div className="font-theme">
      <Routes>
          <Route path="/" element={
              process.env.REACT_APP_UNDER_DEVELOPMENT === "true" ?
              <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-50 bg-red-500 text-white p-2">Almost there! We will be launching soon.</div>
                :
              <MintPage />
          } />
        <Route path="/checker" element={<WalletChecker />} />
      </Routes>
    </div>
  );
}

export default App;
