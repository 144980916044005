import React, { useState, useEffect } from 'react';
import "../../assets/custom-toastify.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../assets/wallet-checker.css"

import walletChecker from "../../utils/walletChecker"

import screenBg from '../../assets/img/mint-bg-pat.webp';
import topLeft from '../../assets/img/mint-top-left.svg';
import mintCounter from '../../assets/img/mint-counter.webp';

import mintUALogo from '../../assets/img/mint-ua-logo.webp';
import mintFlow from '../../assets/img/mint-flow.webp';

import mintVecMain from '../../assets/img/mint-vec-main.svg';
import mintVecMainMobile from '../../assets/img/mint-vec-main-mobile.svg';

import mintBottomLeft from '../../assets/img/mint-bottom-left.webp';
import mintBottomRight from '../../assets/img/mint-bottom-right.webp';
import mintBottomMobile from '../../assets/img/mint-bottom-mobile.webp';



const WalletChecker = () => {




    const roles = {
        2: {
            price: 0.030,
            time: "1 PM, UTC",
            message: "Congratulations, you're indeed registered as an Elder!",
            mints: 6
        },
        1: {
            price: 0.035,
            time: "3 PM, UTC",
            message: "Congratulations, your wallet is Whitelisted!",
            mints: 3
        },
        0: {
            price: 0.039,
            time: "5 PM, UTC",
            message: "I'm sorry, your wallet is not in the AllowList. You still have a very good chance at the Public mint! ",
            mints: 5
        }
    }

    const [address, setAddress] = useState("");
    const [role, setRole] = useState(0);
    const [show, setShow] = useState(false);

    const handleCheck = (addy) => {
        setAddress(address);
        setRole(walletChecker(addy));
        setShow(true);
    }

    const ADDRESS_REGEX =
        new RegExp(/"^0x[a-fA-F0-9]{40}$"/gmi);



    return (
        <>
            <ToastContainer
                theme="dark"/>
            <div className="relative z-10 w-full min-h-screen bg-center bg-cover lg:text-white lg:bg-[#222] child:z-10" style={{ backgroundImage: 'url(' + screenBg + ')' }}>

                {/* LARGE */}

                <div className="absolute top-0 left-0 hidden -z-10 lg:block">
                    <img src={topLeft} alt="" />
                </div>
                <div className="absolute top-0 left-0 z-10 hidden lg:block">
                    <img src={mintCounter} alt="" />
                    {/*<div className="absolute right-28 xl:right-20 bottom-24 xl:bottom-20 rotate-[27deg] text-3xl xl:text-4xl font-ds text-[#F42272]">*/}
                    {/*    <Countdown date={Date.now() + 683140000} renderer={renderer} />*/}
                    {/*</div>*/}
                </div>

                <div className="absolute bottom-0 left-0 hidden w-1/4 -z-10 lg:block">
                    <img src={mintBottomLeft} alt="" />
                </div>
                <div className="absolute bottom-0 right-0 hidden w-1/4 -z-10 lg:block">
                    <img src={mintBottomRight} alt="" />
                </div>
                <div className="hidden w-11/12 mx-auto xl:w-9/12 lg:block">
                    <div className="flex flex-col min-h-screen justify-between">
                        <div className="flex justify-center text-center font-sp text-outline-3 text-[78px] text-[#F8D53B] pt-5">
                            <div className="relative z-10 inline-block">
                                <div className="absolute -top-5 translate-x-1/4 right-full">
                                    <img src={mintUALogo} alt="" className="max-w-max" />
                                </div>
                                <div className="absolute top-0 -translate-x-1/3 left-full -z-10">
                                    <img src={mintFlow} alt="" className="max-w-max" />
                                </div>
                                <div>
                                    WALLET CHECKER
                                </div>
                            </div>
                        </div>

                        <div className="relative flex justify-center pt-10 mt-10">
                            <img src={mintVecMain} alt="" />
                            <div className="absolute z-10 -translate-x-1/2 top-40 left-1/2">
                                <div className="text-5xl text-center xl:text-[62px] font-sp uppercase mb-10">Are you on the ALLOWLIST?</div>

                                <div className="relative flex justify-center">
                                    <p><em>Paste your address here</em></p>
                                    <input type="text" name="address" className="text-[#000]  mb-10 h-10" value={address} onChange={(e) => setAddress(e.target.value)} style={{width:"120%"}}/>
                                </div>

                               <div className="check-button" onClick={() => {(address) ? handleCheck(address) : toast("Please input an address")}}>
                                    <p style={{userSelect: "none"}}>CHECK</p>
                               </div>
                                {(show) ?
                                    <div>
                                <p className="mb-10 text-[#EFD95B] text-lg">{roles[role].message}</p>
                                <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                    <div className="max-w-[75%] shrink-0">SALE STARTS</div>
                                    <div className="w-full">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="shrink-0">{roles[role].time}</div>
                                </div>

                                <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                    <div className="max-w-[75%] shrink-0">MINTS ALLOWED</div>
                                    <div className="w-full">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="shrink-0">{roles[role].mints}</div>
                                </div>

                                <div className="flex items-center gap-4 font-bold text-[22px] mb-10">
                                    <div className="max-w-[75%] shrink-0">MINT PRICE</div>
                                    <div className="w-full">
                                        <div className="w-full h-0.5 bg-white"></div>
                                    </div>
                                    <div className="shrink-0">{roles[role].price} ETH</div>
                                </div>
                                    </div>
                                    : <p style={{color: "#EFD95B", fontSize:"150%"}}>Hey! Enter a wallet add and press check
                                        to see some info about your mint</p>}

                            </div>
                        </div>
                    </div>
                </div>

                {/* SMALL */}
                <div className="lg:hidden">




                    <div className="flex justify-center text-center font-sp text-outline-3 leading-none text-5xl sm:text-[48px] text-[#F8D53B] pt-5">
                        <div className="relative z-10 inline-block">
                            <div className="absolute translate-x-[40%] -top-5 right-full">
                                <img src={mintUALogo} alt="" className="scale-75 max-w-max" />
                            </div>
                            <div className="absolute top-0 -translate-x-1/2 left-full -z-10">
                                <img src={mintFlow} alt="" className="scale-75 max-w-max" />
                            </div>
                            <div>
                                WALLET <br /> CHECKER
                            </div>
                        </div>
                    </div>

                    {/*<div className="flex justify-center py-10 text-5xl font-ds">*/}
                    {/*    <Countdown date={Date.now() + 683140000} renderer={renderer} />*/}
                    {/*</div>*/}

                    <div className="relative flex justify-center pt-10 mt-10 text-white">
                        <img src={mintVecMainMobile} alt="" />
                        <div className="absolute top-28 left-0 h-[calc(100%-224px)] w-full bg-[#F42272]"></div>
                        <div className="absolute z-10 w-full px-5 -translate-x-1/2 top-40 left-1/2">
                            <div className="text-center text-[48px] font-sp uppercase mb-5 leading-none mb-10">ARE YOU ON THE ALLOWLIST?</div>

                            <div className="relative flex justify-center">
                                <input type="text" name="address" className="text-[#000]  mb-10 h-10" value={address} onChange={(e) => setAddress(e.target.value)} style={{width:"120%"}}/>
                            </div>

                            <div className="check-button" onClick={() => {(address) ? handleCheck(address) : toast("Please input an address")}}>
                                <p>CHECK</p>
                            </div>
                            {(show) ?
                                <div>
                            <p className="mb-10 text-[#EFD95B] text-lg">{roles[role].message}</p>
                            <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                <div className="max-w-[75%] shrink-0">SALE STARTS</div>
                                <div className="w-full">
                                    <div className="w-full h-0.5 bg-white"></div>
                                </div>
                                <div className="shrink-0">{roles[role].time}</div>
                            </div>

                            <div className="flex items-center gap-4 font-bold text-[22px] mb-4">
                                <div className="max-w-[75%] shrink-0">MINTS ALLOWED</div>
                                <div className="w-full">
                                    <div className="w-full h-0.5 bg-white"></div>
                                </div>
                                <div className="shrink-0">{roles[role].mints}</div>
                            </div>

                            <div className="flex items-center gap-4 font-bold text-[22px] mb-10">
                                <div className="max-w-[75%] shrink-0">MINT PRICE</div>
                                <div className="w-full">
                                    <div className="w-full h-0.5 bg-white"></div>
                                </div>
                                <div className="shrink-0">{roles[role].price} ETH</div>
                            </div>
                          </div>
                                : <p style={{color: "#EFD95B", fontSize:"150%"}}>Hey! Enter a wallet add and press check
                                to see some info about your mint</p>}

                        </div>
                    </div>

                    <div className="flex justify-center pt-12 -mt-40 bg-black">
                        <img src={mintBottomMobile} alt="" className="z-20" />
                    </div>
                </div>

            </div>
        </>
    )
}

export default WalletChecker