import elders from '../wallets/Elders.json';
import testElders from '../wallets/test-elderlist.json';
import whitelists from '../wallets/Whitelists.json';
import testWhitelists from '../wallets/test-whitelist.json';


export default function WalletChecker(walletAddress) {
    let wallets = process.env.REACT_APP_IS_TESTING === "true" ? testElders : elders;
    let regex = new RegExp(wallets.join("|"), "i");
    if(regex.test(walletAddress.trim())){
        return 2;
    }

    wallets = process.env.REACT_APP_IS_TESTING === "true" ? testWhitelists : whitelists;
    regex = new RegExp(wallets.join("|"), "i");
    if(regex.test(walletAddress.trim())){
        return 1;
    }

    return 0;
}